import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
// import p1 from 'assests/Photos/Clubs/ScienceClub/2023/generalscience/1.jpg';
// import p2 from 'assests/Photos/Clubs/ScienceClub/2023/generalscience/2.jpg';
// import p3 from 'assests/Photos/Clubs/ScienceClub/2023/generalscience/3.jpg';
// import p4 from 'assests/Photos/Clubs/ScienceClub/2023/generalscience/4.jpg';
// import p5 from 'assests/Photos/Clubs/ScienceClub/2023/generalscience/5.jpg';

import SideBarSci from 'views/ScienceClub/SideBarSci';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import Scienceclubsidebar2023 from 'views/ScienceClub/Scienceclubsidebar2023';
import ScienceClubSideBar from 'views/ScienceClubSideBar/ScienceClubSideBar';


const GeneralScience2023 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [config, setConfig] = useState({ base_image_archive_url: '' });
    useEffect(() => {
      fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
        .then(response => response.json())
        .then(data => setConfig(data))
        .catch(error => console.error('Error fetching config:', error));
    }, []);
    
    const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/ScienceClub/generalscience/1.webp`;
    const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/ScienceClub/generalscience/2.webp`;
    const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/ScienceClub/generalscience/3.webp`;
    const p4 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/ScienceClub/generalscience/4.webp`;
    const p5 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/ScienceClub/generalscience/5.webp`;
  
    

    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p4,
            source: p4,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1,
            cols: 1,
        },
        {
          src: p5,
          source: p5,
          rows: 1,
          cols: 1,
      },

    ];

    return (
        <Main>

            <Container>

                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
                                <Typography variant='h5' align='center' >
                                General Science
                                </Typography>
                                <br></br>
                                <Typography variant='h7' align='justify' >
                                Class 3: Picture Narration on One Earth One Family –Save Earth
                                <br></br>
                                Class 4: Picture Narration on One Earth One Family –Save Earth
                                <br></br>
                                Class 5:  Narration on Maintenance of tourist places in India 
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Classes: 3 to 5  &  Date: 30 August to 01 September 2023 
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    The final rounds of Science CCA, held in the fifth week of August saw an active and enthusiastic participation by the students of Classes 3-5.
                                    <br></br>
                                    Students of Classes 3 and 4 displayed their portraits and spoke fluently on the significance  of “One Earth One Family - Save Earth”. They were confident and well prepared for the event thereby showcasing their public speaking skills.
                                    <br></br>
                                    Students of Class 5 spoke voraciously on “Maintenance of tourist places in India”. The students exhibited their love and care for the country by presenting colourful props which made the event interesting.
                                    <br></br>
                                    They were not just successful in echoing the thoughts and mirroring the feelings but also their performances have touched our hearts, brought smiles to our faces, and reminded us of the power of creativity through this activity.
                                </Typography>
                            </Box>
                            {/* <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                "We are here to Express, Impress and Inform"

                                <br />
                            </Typography> */}

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 300 : 200}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={4}>

                        <Box marginBottom={4}>
                            <Scienceclubsidebar2023 />
                        </Box>
                        <Box marginBottom={4}>
                            <ScienceClubSideBar />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Main>


    );
};

export default GeneralScience2023;